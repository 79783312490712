import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "row support_layout"
};
const _hoisted_2 = {
  key: 0,
  class: "iconfont icon-succ"
};
const _hoisted_3 = {
  key: 1,
  class: "iconfont icon-alert"
};
const _hoisted_4 = {
  key: 2,
  class: "iconfont icon-error"
};
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_popover = _resolveComponent("el-popover");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.support, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_el_popover, {
      placement: "bottom-start",
      title: "提示",
      width: 410,
      trigger: "hover"
    }, {
      reference: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass({
          enter_animation: this.enterAnimation,
          green: item.state == 1,
          yellow: item.state == 2,
          grey: item.state == 3,
          red: item.state == 4,
          'delay-0': index == 0,
          'delay-1': index == 1,
          'delay-2': index == 2,
          'delay-3': index == 3,
          'delay-4': index == 4,
          'delay-5': index == 5
        })
      }, [item.state == 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2)) : item.state == 2 || item.state == 4 ? (_openBlock(), _createElementBlock("span", _hoisted_3)) : item.state == 3 ? (_openBlock(), _createElementBlock("span", _hoisted_4)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(item.title), 1)], 2)]),
      default: _withCtx(() => [_createElementVNode("div", {
        class: "popover_tips_layout",
        innerHTML: item.tips
      }, null, 8, _hoisted_5)]),
      _: 2
    }, 1024)]);
  }), 128))]);
}
import api from "@/api/software";
import apiCommon from "@/api/common";
import EmailDialog from "./EmailDialog.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
import { mapState } from "vuex";
export default {
  components: {
    EmailDialog
  },
  computed: { ...mapState(["openBlank"]),

    targetState() {
      if (this.openBlank) {
        return "_blank";
      } else {
        return "_self";
      }
    }

  },
  props: {
    value: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      inputText: null,
      result: null,
      dialogVisible: false,
      lastInputText: null,
      emptyResult: false,
      box1: null
    };
  },

  watch: {
    inputText(val) {
      if (this.isNotNull(val)) {
        this.search(val);
      } else {
        this.result = null;
        this.dialogVisible = false;
      }
    }

  },
  methods: {
    search() {
      let parameter = {
        search: this.inputText,
        size: 1000
      };
      api.getSoftware(parameter, res => {
        if (res.results.length > 0) {
          this.emptyResult = false;
          this.result = this.resultWeightSort(res.results, parameter.search);

          if (!this.dialogVisible) {
            this.dialogVisible = true;
          }
        } else {
          this.result = null;

          if (!this.emptyResult) {
            this.dialogVisible = false;
          }
        }
      });
    },

    enterSearch() {
      if (this.result == null && this.isNotNull(this.inputText)) {
        this.emptyResult = true;
        this.dialogVisible = true;
      }
    },

    enterSearch2() {
      if (this.result == null && this.isNotNull(this.inputText)) {
        setTimeout(() => {
          this.emptyResult = true;
          this.dialogVisible = true;
        }, 200);
      }
    },

    toUrl(data) {
      let url = "";

      if (data.type == 1) {
        url = "/software/" + data.id;
      } else if (data.type == 2) {
        url = "/software/collection/" + data.id;
      }

      return url;
    },

    inputBlur() {
      this.lastInputText = this.inputText;
    },

    inputFocus() {
      if (this.lastInputText == this.inputText) {
        if (this.result != null) {
          this.dialogVisible = true;
        }
      }
    },

    pagerClick(e) {
      if (this.dialogVisible) {
        var wx = e.clientX;
        var wy = e.clientY;
        let box2 = this.$refs.resultBox.getBoundingClientRect(); // 判断如果点击在结果框外，关闭结果框

        if (wx < box2.left || wy < this.box1.top || wx > box2.right || wy > box2.bottom) {
          this.dialogVisible = false;
        }
      }
    },

    /*
    根据权重进行排序
    减轻服务器压力
    1、软件名字中有搜索关键字,并且是以关键字开头，权重1级
    2、软件关键字中有完全匹配关键字，权重2级
    */
    resultWeightSort(result, searchText) {
      result.forEach(element => {
        let index = element.name.toUpperCase().indexOf(searchText.toUpperCase());

        if (index == -1 && element.keyword != null) {
          for (const item of element.keyword.split(" ")) {
            if (item == searchText) {
              index = 1.5;
              break;
            }
          }
        }

        element.weight = index == -1 ? 999 : index;
      });
      return result.sort((a, b) => {
        return a.weight - b.weight;
      });
    },

    emptyResultSubmti() {
      apiCommon.proposal("主页搜索 & " + this.inputText, 1, res => {
        this.$refs.email.open(res.id);
      });
      this.dialogVisible = false;
    },

    sendEmail(id, email) {
      apiCommon.proposalEmail(id, email);
      ElMessage({
        message: "收到！",
        type: "success"
      });
    },

    keywordText(text) {
      if (this.isNotNull(text)) {
        let keyIndex = text.toUpperCase().indexOf(this.inputText.toUpperCase());

        if (keyIndex != -1) {
          let start = text.slice(0, keyIndex);
          let key = text.slice(keyIndex, this.inputText.length + keyIndex);
          let end = text.slice(this.inputText.length + keyIndex, text.length);
          let keyText = "";

          if (start.length > 0) {
            keyText = start;
          }

          if (key.length > 0) {
            keyText += "<span class='keyword'>" + key + "</span>";
          }

          if (end.length > 0) {
            keyText += end;
          }

          return keyText;
        } else {
          return text;
        }
      }

      return "";
    }

  },

  mounted() {
    window.addEventListener("click", this.pagerClick);
    this.box1 = this.$refs.searchBox.getBoundingClientRect();
  },

  unmounted() {
    window.removeEventListener("click", this.pagerClick);
  }

};
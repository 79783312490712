import {
  POST,
  PATCH
} from '../common/api/service.js'
// import progress from '@/common/nprogress'

const URL = {
  proposal: 'service/common/proposal/',
}
const api = {
  proposal(content, type, succ) {
    let params = {
      content: content,
      type: type
    }
    let requestOptions = {
      path: URL.proposal,
      params: params,
      succ: succ,
    }

    POST(requestOptions)
  },
  proposalEmail(id, email) {
    let params = {
      email: email,
    }
    let requestOptions = {
      path: URL.proposal + id + "/",
      params: params,
    }
    PATCH(requestOptions)
  }
}
export default api

export default {
  props: {
    self: {
      type: Boolean,
      default: true
    },
    value: {
      type: String
    }
  }
};
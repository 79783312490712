import YzChatContent from "./YzChatContent";
import YzChatSend from "./YzChatSend";
import { WEB_SOCKET_URL } from "@/common/api/url";
import history from "./history";
import api from "@/api/chat";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  components: {
    YzChatContent,
    YzChatSend
  },
  props: {
    value: {
      type: String
    }
  },

  data() {
    return {
      chatSocket: null,
      target: null,
      name: null,
      online: null,
      message: [],
      open: null,
      unread: 0,
      unreadData: [],
      chatSocketOpen: false,
      barWriting: false,
      headImg: null,
      tips: null,
      kick: false,
      messageRemindId: null,
      messageRemindTitle: null,
      pageHidden: false
    };
  },

  methods: {
    sendState(data) {
      let msg = {
        content: data
      };
      this.chatSocket.send(JSON.stringify(msg));
    },

    startUpload(data) {
      this.message.push(data);
      this.$refs.chatContent.setMessage();
    },

    succUpload(data) {
      this.chatSocket.send(JSON.stringify(data));
    },

    errorUpload(data) {
      this.modifyMessage(this.message, data);
    },

    sendMessage(data) {
      let msg = {
        self: true,
        content: data
      };
      this.message.push(msg);
      this.$refs.chatContent.setMessage();
      this.chatSocket.send(JSON.stringify(msg));
    },

    defaultTarget(data) {
      this.target = data.id;
    },

    modifyMessage(targetMessage, data) {
      if (targetMessage != null && targetMessage.length > 0) {
        let end = targetMessage.length - 5 >= 0 ? targetMessage.length - 5 : 0;

        for (let index = targetMessage.length - 1; index >= end; index--) {
          const element = targetMessage[index];

          if (this.isNotNull(element.content) && element.content.id == data.content.id) {
            // 除了原content.data，其他替换
            data.content.data = targetMessage[index].content.data;
            targetMessage[index] = data;
            return true;
          }
        }
      }

      return false;
    },

    initMessage() {
      history.init();
      this.message = history.getHistory();

      if (this.unreadData.length > 0) {
        for (const item of this.unreadData) {
          this.message.push(JSON.parse(item.content));
        }

        api.read();
      }
    },

    startChat() {
      this.initMessage();
      this.chatSocket = new WebSocket(WEB_SOCKET_URL + "message/tempChat/");

      this.chatSocket.onmessage = data => {
        let message = JSON.parse(data.data);
        this.receiveMessage(message);
        this.$refs.chatContent.setMessage();
      };

      this.chatSocket.onopen = () => {
        this.chatSocketOpen = true;
        this.tips = null;
      };

      this.chatSocket.onclose = e => {
        if (e.code == 3001) {
          this.kick = true;
          this.tips = "您已在其他页面登录，当前页面将无法发送和接受消息。可以刷新页面后重新打开。";
        } else {
          this.kick = false;
        }

        this.chatSocketOpen = false;
      };
    },

    receiveMessage(message) {
      if (!message.self) {
        switch (message.content.type) {
          case this.CHAT_NET:
            this.headImg = message.head;

            if (message.content.data == "在线") {
              this.online = true;
            } else {
              this.online = false;
            }

            this.name = message.name[0];
            break;

          case this.CHAT_STATE:
            this.preMessage(message);
            break;

          default:
            // 没有打开聊天框就增加一次未读
            if (this.open != true) {
              this.unread++;
            }

            history.addHistory(message);
            this.message.push(message);

            if (this.pageHidden && this.messageRemindId == null) {
              let remind = this.messageRemind();
              let timer = remind.timer;
              this.messageRemindTitle = remind.title;
              this.messageRemindId = timer();
            }

            break;
        }
      } else {
        history.addHistory(message);
        this.confirmMessage(message);
      }
    },

    // 确认自己发出的信息
    confirmMessage(newMsg) {
      console.log(newMsg);

      if (!this.modifyMessage(this.message, newMsg)) {
        // 修改失败说明没有找到
        // 如果没有找到，说明是同账号其他端口发出的，添加至末尾（正确应该根据时间插入）
        if (this.message != null) {
          this.message.push(newMsg);
        } else {
          this.message = [newMsg];
        }
      }
    },

    closeChat() {
      this.open = false;
    },

    openChat() {
      if (!this.chatSocketOpen) {
        this.startChat();
      }

      this.unread = 0;
      this.open = true;
    },

    preMessage(newMsg) {
      if (newMsg.content.data == this.START_W) {
        //bar气泡写入动画
        this.barWriting = true; // 检查是否存在正在输入
        // 存在删除增加新的

        if (this.message != null) {
          for (let i = this.message.length - 1; i >= 0; i--) {
            let msg = this.message[i];

            if (msg.content.type == this.CHAT_STATE) {
              this.message.splice(i, 1);
            }
          }

          this.message.push(newMsg);
        } else {
          this.message = [newMsg];
        }
      } else if (newMsg.content.data == this.STOP_W) {
        //bar气泡写入动画
        this.barWriting = false;

        if (this.message != null) {
          for (let i = this.message.length - 1; i >= 0; i--) {
            let msg = this.message[i];

            if (msg.content.type == this.CHAT_STATE) {
              this.message.splice(i, 1);
            }
          }
        }
      }
    },

    messageRemind() {
      let sourceTitle = document.title;
      document.title = "有您的消息";
      return {
        timer: () => {
          let id = setInterval(function () {
            let content = document.title;
            var firstStr = content.charAt(0); // 获取第一个字

            var surplue = content.substring(1, content.length); // 删除第一个字

            var new_content = surplue + firstStr; // 把第一个字加到最后面

            document.title = new_content;
          }, 1000);
          return id;
        },
        title: sourceTitle
      };
    }

  },

  mounted() {
    api.unread(res => {
      if (res != null && res.length > 0) {
        this.unread = res.length;
        this.unreadData = res;
        setTimeout(() => {
          ElMessage({
            message: "店长给您发来了消息，请注意查看！",
            type: "success"
          });
        }, 500);
      }
    });
    document.addEventListener("visibilitychange", () => {
      var tag = document.hidden || document.webkitHidden;

      if (tag) {
        this.pageHidden = true;
      } else {
        this.pageHidden = false;

        if (this.messageRemindId) {
          clearInterval(this.messageRemindId);
          document.title = this.messageRemindTitle;
          this.messageRemindId = null;
        } // 回到页面如果断线了，重新连接


        if (!this.chatSocketOpen && this.open && !this.kick) {
          this.startChat();
        }
      }
    });
  }

};
import { HTTP_URL } from "@/common/api/url";
export default {
  data() {
    return {
      url: HTTP_URL + "service/message/tempPicture/",
      fileName: "tempPicture",
      id: null,
      result: null
    };
  },

  methods: {
    startUpload(uploadFile) {
      this.id = this.generateId();
      var reader = new FileReader();
      reader.readAsDataURL(uploadFile);

      reader.onload = e => {
        this.result = e.currentTarget.result;
        let data = {
          type: this.CHAT_PICTURE,
          state: this.CHAT_UPLOAD_STATE_PER,
          data: this.result,
          id: this.id
        };
        let msg = {
          self: true,
          content: data
        };
        this.$emit("startUpload", msg);
      };
    },

    succUpload(response) {
      if (response.code == "000000") {
        let data = {
          type: this.CHAT_PICTURE,
          state: this.CHAT_UPLOAD_STATE_SUCC,
          data: response.data.url,
          id: this.id
        };
        let msg = {
          self: true,
          content: data
        };
        this.$emit("succUpload", msg);
      } else {
        let data = {
          type: this.CHAT_PICTURE,
          state: this.CHAT_UPLOAD_STATE_ERROR,
          data: this.result,
          id: this.id
        };
        let msg = {
          self: true,
          content: data
        };
        this.$emit("errorUpload", msg);
      }
    },

    errorUpload() {
      let data = {
        type: this.CHAT_PICTURE,
        state: this.CHAT_UPLOAD_STATE_ERROR,
        data: this.result,
        id: this.id
      };
      let msg = {
        self: true,
        content: data
      };
      this.$emit("errorUpload", msg);
    }

  },

  mounted() {}

};
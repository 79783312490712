import api from "@/api/software";
import SupportView from "./SupportView.vue";
import VersionsSwitch from "./VersionsSwitch.vue";
import VersionsDetails from "./VersionsDetails.vue";
import DownloadDialog from "./DownloadDialog.vue";
import SoftwareHeader from "@/components/header/SoftwareHeader.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
import YzLike from "@/components/YzLike.vue";
export default {
  name: "SoftwareDetails",
  props: {},
  components: {
    SupportView,
    VersionsSwitch,
    VersionsDetails,
    SoftwareHeader,
    DownloadDialog,
    YzLike
  },

  data() {
    return {
      data: {
        picture: null,
        name: null,
        slogan: null,
        type: null,
        macVersions: null,
        windowsVersions: null,
        describe: null,
        like: 0
      },
      headerData: {
        name: null,
        picture: null
      },
      support: null,
      os: -1,
      versions: {
        versions: {
          name: null
        }
      },
      headerEnterState: false,
      headerEnterX: 1000,
      domReady: false
    };
  },

  computed: {
    downloadLink() {
      let link = this.versions.versions.link;

      if (this.isNotNull(link)) {
        return link;
      } else {
        return null;
      }
    }

  },
  methods: {
    getDetails() {
      api.getSoftwareDetails(this.$route.params.id, res => {
        this.data = res;
        let titleName = res.name;

        if (res.slogan != null) {
          titleName += " " + res.slogan;
        }

        document.title = titleName;
        this.headerData = {
          name: res.name,
          picture: res.picture
        };
        this.$refs.versionsSwitch.importData({
          mac: this.deepCopy(res.macVersions),
          win: this.deepCopy(res.windowsVersions)
        });
      });
    },

    findReadme(data, relation) {
      let target = data.find(e => {
        return e.id == relation;
      });

      if (target != null) {
        return target.readme;
      }

      return null;
    },

    versionChange(data) {
      this.os = data.type;

      if (data.versions.relation != -1 && data.versions.relation != null) {
        if (data.type == this.WIN) {
          data.versions.readme = this.findReadme(this.windowsVersions);
        } else {
          data.versions.readme = this.findReadme(this.macVersions);
        }
      }

      this.versions = {
        id: this.$route.params.id,
        name: this.data.name,
        type: data.type,
        versions: data.versions,
        describe: this.data.describe,
        officialWebsite: this.data.officialWebsite
      };

      if (data.versions != null) {
        this.support = {
          type: data.type,
          os: data.versions.os,
          support: data.versions.support
        };
      } else {
        this.support = null;
      }
    },

    download() {
      this.$refs.downloadDialog.open();
    },

    downloadRecord() {
      if (this.os == this.WIN) {
        api.windowsDownload(this.$route.params.id, this.versions.versions.id);
      } else {
        api.macDownload(this.$route.params.id, this.versions.versions.id);
      }
    },

    longLike() {
      api.softwareLike(this.$route.params.id, 10);
      ElMessage({
        message: "感谢您的爆赞",
        type: "success"
      });
    },

    like() {
      api.softwareLike(this.$route.params.id);
    },

    anchor(index) {
      this.$refs.versionsDetails.anchor(index);
    },

    scrollEvent() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let header = this.$refs.header; // 离开

      if (scrollTop < this.headerEnterX && this.headerEnterState && header != null) {
        this.headerEnterState = false;
        header.leave();
      } // 进入


      if (scrollTop > this.headerEnterX && !this.headerEnterState && header != null) {
        this.headerEnterState = true;
        header.enter();
      }

      let versionsDetails = this.$refs.versionsDetails;

      if (versionsDetails != null) {
        // 页面总高度-显示高度
        let bottom = document.body.offsetHeight - document.documentElement.clientHeight - 50;

        if (scrollTop > versionsDetails.quote3Top || scrollTop >= bottom) {
          this.$refs.header.setAnchor(3);
        } else if (scrollTop > versionsDetails.quote2Top) {
          this.$refs.header.setAnchor(2);
        } else if (scrollTop > versionsDetails.quote1Top) {
          this.$refs.header.setAnchor(1);
        }
      }
    }

  },

  created() {
    this.getDetails();
  },

  mounted() {
    // 减去的60为header的高度
    this.$nextTick(() => {
      this.headerEnterX = this.$refs.headerTransform.getBoundingClientRect().top - 60;
      window.addEventListener("scroll", this.scrollEvent);
    });
  }

};
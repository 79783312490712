import api from "@/api/software";
import SoftwareGroup from "./SoftwareGrid.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  components: {
    SoftwareGroup
  },

  data() {
    return {
      data: {
        picture: null,
        name: null,
        slogan: null,
        collection: null
      }
    };
  },

  methods: {
    getDetails() {
      api.getSoftwareDetails(this.$route.params.id, res => {
        this.data = res;
        let titleName = res.name;

        if (res.slogan != null) {
          titleName += " " + res.slogan;
        }

        document.title = titleName;
        this.headerData = {
          name: res.name,
          picture: res.picture
        };
      });
    },

    longLike(id) {
      api.softwareLike(id, 10);
      ElMessage({
        message: "感谢您的爆赞",
        type: "success"
      });
    },

    like(id) {
      api.softwareLike(id);
    }

  },

  created() {
    this.getDetails();
  }

};
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "like_but",
    onMousedown: _cache[0] || (_cache[0] = (...args) => $options.likestart && $options.likestart(...args)),
    onMouseup: _cache[1] || (_cache[1] = (...args) => $options.likestop && $options.likestop(...args)),
    onMouseout: _cache[2] || (_cache[2] = (...args) => $options.likestop && $options.likestop(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass({
      shake: $data.likeLong,
      iconfont: true,
      active: $data.isLike,
      'icon-like': true
    })
  }, null, 2), _createElementVNode("span", null, _toDisplayString($data.likeNum), 1)], 32);
}
import "core-js/modules/es.array.includes.js";
import TextView from "@/components/TextView.vue";
import QuoteView from "@/components/QuoteView.vue";
import QuestionView from "./QuestionView.vue";
import { scrollTo } from "@/common/scrollTo.js";
export default {
  name: "VersionsDetails",
  components: {
    TextView,
    QuoteView,
    QuestionView
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    value(val) {
      if (val != null) {
        let versions = val.versions;
        this.readme = val.versions.readme;
        this.describe = val.describe;
        this.officialWebsite = val.officialWebsite;
        this.type = val.type;
        let info = [];

        if (this.isNotNull(versions.size)) {
          info.push({
            key: "大小",
            value: versions.size.slice(0, versions.size.length - 2),
            tag: versions.size.slice(versions.size.length - 2, versions.size.length)
          });
        } else {
          info.push({
            key: "大小",
            value: "未知",
            tag: "-"
          });
        }

        info.push({
          key: "下载",
          value: versions.count | 0,
          tag: "次"
        });

        if (this.isNotNull(versions.language)) {
          if (versions.language.includes("中文")) {
            info.push({
              key: "语言",
              value: "中文",
              tag: "CN"
            });
          } else if (versions.language.includes("英文")) {
            info.push({
              key: "语言",
              value: "英文",
              tag: "EN"
            });
          }
        } else {
          info.push({
            key: "语言",
            value: "未知",
            tag: "-"
          });
        }

        if (this.isNotNull(versions.os)) {
          if (val.type == this.WIN) {
            let os = "";

            if (versions.os == "all") {
              os = "全平台";
            } else {
              os = versions.os;
            }

            info.push({
              key: "兼容",
              value: os,
              tag: "windows"
            });
          } else {
            info.push({
              key: "兼容",
              value: ">= " + versions.os.split(" ")[0],
              tag: "mac OS"
            });
          }
        } else {
          let tag = '';

          if (val.type == this.WIN) {
            tag = "windows";
          } else {
            tag = "mac OS";
          }

          info.push({
            key: "兼容",
            value: "未知",
            tag: tag
          });
        }

        this.infoData = info;
        this.updateQuptePosition();
      }
    }

  },

  data() {
    return {
      infoData: [],
      readme: null,
      describe: null,
      officialWebsite: null,
      type: 1,
      quote1Top: 0,
      quote2Top: 0,
      quote3Top: 0
    };
  },

  methods: {
    anchor(index) {
      switch (index) {
        case 1:
          scrollTo(this.quote1Top);
          break;

        case 2:
          scrollTo(this.quote2Top);
          break;

        case 3:
          scrollTo(this.quote3Top);
          break;
      }
    },

    updateQuptePosition() {
      setTimeout(() => {
        this.quote1Top = this.$refs.quote1.top() - 90;
        this.quote2Top = this.$refs.quote2.top() - 90;
        this.quote3Top = this.$refs.quote3.top() - 90;
      }, 1000);
    }

  }
};
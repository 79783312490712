import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "tag_layout",
    style: _normalizeStyle({
      backgroundColor: $data.style.bg
    })
  }, [_createElementVNode("div", {
    class: "tag_icon",
    style: _normalizeStyle({
      backgroundColor: $data.style.iconBg
    })
  }, [_createElementVNode("span", {
    class: _normalizeClass({
      icon: true,
      iconfont: true,
      'icon-windows': $props.value == 1,
      'icon-mac': $props.value == 2,
      'icon-all': $props.value == 3
    })
  }, null, 2)], 4), _createElementVNode("span", {
    class: "tag_text",
    style: _normalizeStyle({
      color: $data.style.textBg,
      paddingRight: $data.style.padding
    })
  }, _toDisplayString($data.style.text), 5)], 4);
}
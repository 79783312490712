import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-8490e4f6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "yz_radio_layout"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      onClick: $event => $options.choice(item.id)
    }, [_createElementVNode("span", {
      class: _normalizeClass({
        icon_yz_radio: true,
        iconfont: true,
        active: $data.cur == item.id,
        'icon-circle': $data.cur != item.id,
        'icon-succ': $data.cur == item.id
      })
    }, null, 2), _createElementVNode("span", {
      class: _normalizeClass({
        radio_title: true,
        active: $data.cur == item.id
      })
    }, _toDisplayString(item.title), 3)], 8, _hoisted_2);
  }), 128))]);
}
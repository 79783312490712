const history = {
  KEY: "temp_chat_history",
  CHAT_TEMP_STATE: 101,
  historyData: [],
  historyStateData: [],
  init() {
    try {
      let h = localStorage.getItem(this.KEY)
      if (h != null && h.length > 0) {
        this.historyData = JSON.parse(h)
        let lastTime = 0
        for (const item of this.historyData) {
          if (item.time - lastTime >= 86400) {
            let stateData = {
              state: true,
              time: item.time
            }
            this.historyStateData.push(stateData)
          }
          lastTime = item.time
          this.historyStateData.push(item)
        }
      }
    } catch (err) { console.log(err) }
  },
  save() {
    // localStorage.setItem(this.KEY, JSON.stringify(this.historyData))
  },
  addHistory(data) {
    if (this.historyData.length >= 100) {
      this.historyData.pop()
    }
    this.historyData.push(data)
    localStorage.setItem(this.KEY, JSON.stringify(this.historyData))

  },
  getHistory() {
    return this.historyStateData
  }
}
export default history
import api from "@/api/common";
import EmailDialog from "@/components/EmailDialog.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  components: {
    EmailDialog
  },

  data() {
    return {
      dialogVisible: false,
      id: null,
      activeTag: '1',
      res: null,
      reason: null,
      proposel: null,
      name: null,
      resWrong: false,
      reasonWrong: false,
      proposelWrong: false
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
    },

    submit() {
      if (this.activeTag == "1") {
        if (this.isNull(this.res)) {
          this.resWrong = true;
          return;
        } else {
          this.resWrong = false;
        }

        if (this.isNull(this.reason)) {
          this.reasonWrong = true;
          return;
        } else {
          this.reasonWrong = false;
        }

        api.proposal("资源推荐 & " + this.res + " & " + this.reason + " & " + (this.name || ''), 3, res => {
          this.$refs.email.open(res.id);
        });
        this.res = null;
        this.reason = null;
        this.name = null;
      } else {
        if (this.isNull(this.proposel)) {
          this.proposelWrong = true;
          return;
        } else {
          this.proposelWrong = false;
        }

        api.proposal("资源建议 & " + this.proposel, 3, res => {
          this.$refs.email.open(res.id);
        });
        this.proposel = null;
      }

      this.dialogVisible = false;
    },

    sendEmail(id, email) {
      api.proposalEmail(id, email);
      ElMessage({
        message: "收到！",
        type: "success"
      });
    }

  }
};
export default {
  data() {
    return {
      detailsDialog: false,
      showImg: null
    };
  },

  methods: {
    trigger() {
      this.detailsDialog = false;
    },

    open(data) {
      this.showImg = data;
      this.detailsDialog = true;
    }

  }
};
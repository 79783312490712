import api from "@/api/resource";
import TextView from "@/components/TextView.vue";
export default {
  components: {
    TextView
  },
  computed: {
    dialogWidth() {
      if (this.size == 1) {
        return "460px";
      } else {
        return "680px";
      }
    }

  },

  data() {
    return {
      dialogVisible: false,
      id: null,
      contetn: null,
      link: null,
      size: 1
    };
  },

  methods: {
    open(id, content, link, size) {
      this.id = id;
      this.contetn = content;
      this.link = link;
      this.size = size;
      this.dialogVisible = true;
    },

    visit() {
      api.count(this.id);
    }

  }
};
import api from "@/api/resource";
import ResouceContentDialog from "./ResouceContentDialog.vue";
export default {
  components: {
    ResouceContentDialog
  },
  props: {
    value: {
      type: Array,
      default: () => {}
    }
  },
  watch: {
    value(val) {
      this.data = val;
    }

  },

  data() {
    return {
      data: this.value
    };
  },

  methods: {
    describe(data) {
      if (this.isNotNull(data.des)) {
        return data.des;
      } else {
        return data.describe;
      }
    },

    medalColor1(index) {
      switch (index) {
        case 0:
          return "#F5B200";

        case 1:
          return "#B6B6B6";

        case 2:
          return "#FF8955";

        default:
          return "#FF8955";
      }
    },

    medalColor2(index) {
      switch (index) {
        case 0:
          return "#FFD524";

        case 1:
          return "#A2A2A2";

        case 2:
          return "#D8511D";

        default:
          return "#D8511D";
      }
    },

    link(data) {
      if (this.isNotNull(data.content)) {
        return "javascript:;";
      }

      return data.link;
    },

    linkTarget(data) {
      if (this.isNull(data.content) && this.isNotNull(data.link)) {
        return "_blank";
      }

      return null;
    },

    toResource(data) {
      if (this.isNotNull(data.content)) {
        this.$refs.resouceContentDialog.open(data.id, data.content, data.link, data.size);
      } else {
        api.count(data.id);
      }
    }

  }
};
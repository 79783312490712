import index from '@/pages/article/ArticleIndex.vue'
import details from '@/pages/article/ArticleDetails.vue'

export default [
  {
    path: '/article/all',
    name: 'articleIndex',
    component: index,
    meta: {
      title: '原子闪修 - 电脑百科全书'
    }
  }, {
    path: '/article/:id',
    name: 'article',
    component: details,
    meta: {
      title: '原子闪修 - 电脑百科全书'
    }
  }
]
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      styles: [{
        bg: "#ebf7ff",
        iconBg: "#2aa5fc",
        textBg: "#69b8f1",
        text: "win",
        padding: "4px"
      }, {
        bg: "#ffe8ec",
        iconBg: "#ff5e62",
        textBg: "#ff6c70",
        text: "mac",
        padding: "4px"
      }, {
        bg: "#ebf7ff",
        iconBg: "#ff5e62",
        textBg: "#69b8f1",
        text: "all",
        padding: "8px"
      }],
      style: null
    };
  },

  created() {
    switch (this.value) {
      case 1:
        this.style = this.styles[0];
        break;

      case 2:
        this.style = this.styles[1];
        break;

      case 3:
        this.style = this.styles[2];
        break;
    }
  }

};
import api from "@/api/software";
import apiCommon from "@/api/common";
import EmailDialog from "@/components/EmailDialog.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  components: {
    EmailDialog
  },
  computed: {
    osName() {
      if (this.value.type == this.WIN) {
        return "windows";
      } else {
        return "mac OS";
      }
    },

    support() {
      if (this.value.type == this.WIN) {
        return this.value.versions.support;
      } else {
        if (this.value.versions.os) {
          return "需" + this.value.versions.os.split(" ")[0] + "或更高版本";
        } else {
          return "";
        }
      }
    },

    link1() {
      if (this.value != null && this.isNotNull(this.value.versions.link)) {
        return this.value.versions.link;
      } else {
        return null;
      }
    },

    link2() {
      if (this.value != null && this.isNotNull(this.value.versions.link2)) {
        return this.value.versions.link2;
      } else {
        return null;
      }
    },

    link3() {
      if (this.value != null && this.isNotNull(this.value.versions.link3)) {
        return this.value.versions.link3;
      } else {
        return null;
      }
    }

  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          type: null,
          versions: {
            name: null
          }
        };
      }
    }
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
    },

    download(type) {
      switch (type) {
        case 1:
          try {
            if (this.value.versions.link) {
              this.recordDownload();
              window.open(this.value.versions.link, "_blank");
            }
          } catch {
            return;
          }

          break;

        case 2:
          try {
            if (this.value.versions.link2) {
              this.recordDownload();
              window.open(this.value.versions.link2, "_blank");
            }
          } catch {
            return;
          }

          break;

        case 3:
          try {
            if (this.value.versions.link) {
              this.recordDownload();
              window.open(this.value.versions.link3, "_blank");
            }
          } catch {
            return;
          }

          break;
      }
    },

    recordDownload() {
      if (this.value != null && this.value.versions != null) {
        if (this.value.type == this.WIN) {
          api.windowsDownload(this.value.id, this.value.versions.id);
        } else {
          api.macDownload(this.value.id, this.value.versions.id);
        }
      }
    },

    invalid() {
      this.dialogVisible = false;
      let content = "下载失效 & " + this.value.name + "-" + this.value.versions.name;
      apiCommon.proposal(content, 1, res => {
        this.$refs.email.open(res.id);
      });
    },

    sendEmail(id, email) {
      apiCommon.proposalEmail(id, email);
      ElMessage({
        message: "收到！",
        type: "success"
      });
    }

  }
};
import api from "@/api/software";
import SoftwareSearch from "./SoftwareSearch.vue";
import SoftwareGroup from "./SoftwareGrid.vue";
import MoreView from "@/components/MoreView.vue";
export default {
  components: {
    SoftwareSearch,
    SoftwareGroup,
    MoreView
  },

  data() {
    return {
      data: null,
      countNumber: 0,
      searchData: [],
      height: 0
    };
  },

  methods: {
    getCategory() {
      api.getSoftwareCategory(res => {
        this.data = res;
      });
    },

    getCountNumber() {
      api.getCountNumber(res => {
        this.countNumber = res;
      });
    }

  },

  mounted() {
    window.onresize = () => (() => {
      this.$refs.softwareGroup.forEach(e => {
        e.dataSizeChange(document.body.clientWidth);
      });
    })();
  },

  created() {
    this.getCategory();
    this.getCountNumber();
  }

};
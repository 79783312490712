import useClipboard from "vue-clipboard3";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  emits: ["titleLink"],

  setup() {
    const {
      toClipboard
    } = useClipboard();

    const copy = async val => {
      try {
        await toClipboard(val);
        ElMessage({
          message: "内容已复制",
          type: "success"
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      copy
    };
  },

  props: {
    value: {
      type: String
    }
  },
  watch: {
    value() {
      this.setTextView();
    }

  },
  methods: {
    setTextView() {
      this.$nextTick(function () {
        let textView = document.getElementById("textview");
        let a = textView.getElementsByTagName("a");

        for (let element of a) {
          this.aLink(element);
        }

        let code = textView.getElementsByTagName("code");

        for (let element of code) {
          element.onclick = () => {
            this.copy(element.innerText);
          };
        }

        let u = textView.getElementsByTagName("u");

        for (let element of u) {
          element.onclick = () => {
            setTimeout(() => {
              this.copy(element.innerText);
            }, 100);
          };
        }

        let h2 = textView.getElementsByTagName("h2");

        for (let element of h2) {
          let a = element.getElementsByTagName("a");

          if (a.length > 0) {
            a[0].onclick = () => {
              this.$emit('titleLink', a[0]);
              return true;
            };
          }
        }
      });
    },

    navPages(nav, id, aElement) {
      aElement.onclick = () => {
        switch (nav) {
          case "software":
            window.open("/software/" + id, "_blank");
            break;

          case "question":
            window.open("/software/qa/" + id, "_blank");
            break;
        }

        return false;
      };
    },

    aLink(aElement) {
      let element = aElement.outerHTML;
      var re = new RegExp("<a[^>]*href=[\"']?([^\"' ]+)[\"']?[^.]+>(.*)</a>", "ig");
      re.exec(element);
      var url = RegExp.$1;
      let urls = url.split("/");

      if (urls.length >= 2) {
        let command = urls[0];

        switch (command) {
          case "copy":
            aElement.onclick = () => {
              this.copy(url.substr(5, url.length));
              return false;
            };

            break;

          case "pages":
            if (urls.length >= 3) {
              this.navPages(urls[1], urls[2], aElement);
            }

            break;
        }
      } // let navigateUrl = url.substr(url.indexOf("software") + 9, url.length);
      // let command = navigateUrl.substr(0, navigateUrl.indexOf("/"));

    }

  },

  mounted() {
    this.setTextView();
  },

  created() {
    this.$watch(() => this.$route.params, toParams => {
      this.$emit("to", toParams.id);
    });
  }

};
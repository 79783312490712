export default {
  emits: ['email'],

  data() {
    return {
      dialogVisible: false,
      email: null,
      emailWrong: false,
      id: null
    };
  },

  methods: {
    open(id) {
      this.emailWrong = false;
      this.id = id;
      this.dialogVisible = true;
    },

    submit() {
      if (this.checkEmail(this.email)) {
        this.emailWrong = false;
      } else {
        this.emailWrong = true;
        return;
      }

      this.$emit('email', this.id, this.email);
      localStorage.setItem("email", this.email);
      this.dialogVisible = false;
    }

  },

  created() {
    this.email = localStorage.getItem("email");
  }

};
export default {
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    default: {
      type: Number
    },
    replace: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    }
  },
  watch: {
    data() {
      this.active();
    }

  },

  data() {
    return {
      curPath: -1
    };
  },

  methods: {
    text(data) {
      return data[this.name];
    },

    active() {
      if (this.data != null && this.data.length > 0) {
        if (this.default != null) {
          this.curPath = this.data.find(e => {
            return e.id == this.default;
          }).url;
        }
      }
    }

  },

  created() {
    this.active();
    this.$watch(() => this.$route, to => {
      if (this.data != null && this.data.length > 0) {
        let result = this.$emit('choice', to);

        if (result == undefined || result == null) {
          let target = this.data.find(e => {
            return e.url.indexOf(to.path) == 0;
          });

          if (target != null) {
            this.curPath = target.url;
          }
        } else {
          this.curPath = result;
        }
      }
    });
  }

};
import {
  GET
} from '../common/api/service.js'

const URL = {
  unread: 'service/message/tempUnread/',
  read: 'service/message/tempRead/',
}
const api = {
  unread(succ) {
    let requestOptions = {
      path: URL.unread,
      succ: succ,
    }
    GET(requestOptions)
  },
  read(succ) {
    let requestOptions = {
      path: URL.read,
      succ: succ,
    }
    GET(requestOptions)
  }
}
export default api

const TOKEN = "token"

let token = null

const auth = {
	setToken(value) {
		localStorage.setItem(TOKEN, value)
		token = value
	},
	removeToken() {
		localStorage.removeItem(TOKEN)
		token = null
	},
	getToken() {
		if (token == null) {
			token = localStorage.getItem(TOKEN);
		}
		return token
	}
}

export default auth

import {
  GET,
} from '../common/api/service.js'
import progress from '@/common/nprogress'

const URL = {
  main: "service/information/apiArticle/",
  like: "service/information/articleLike/",
}
const api = {
  like(id, num = 1) {
    let path = null
    if (num > 1) {
      path = URL.like + id + "/?num=" + num
    } else {
      path = URL.like + id + "/"
    }
    let requestOptions = {
      path: path,
    }
    GET(requestOptions)
  },
  getDataDetails(id, succ) {
    let requestOptions = {
      path: URL.main + id + "/",
      succ: succ,
      complete: () => {
        progress.done()
      }
    }
    GET(requestOptions)
  },
}
export default api

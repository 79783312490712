import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1c9cec95"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "software"
};
const _hoisted_2 = {
  class: "icon_layout"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "versions_layout"
};
const _hoisted_5 = {
  class: "sub_title"
};
const _hoisted_6 = {
  class: "soft_title_layout"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  class: "sub_title spacing_top_1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  const _component_o_s_tag = _resolveComponent("o-s-tag");

  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      container: true,
      software_group: !$props.s,
      software_group_s: $props.s
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (software, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: software.id,
      class: _normalizeClass($data.softwareAnim[index])
    }, [_createVNode(_component_router_link, {
      to: $options.toUrl(software),
      target: $options.targetState
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        class: "software_icon_bg",
        src: _ctx.imgUrl(software.picture)
      }, null, 8, _hoisted_3), _createVNode(_component_el_image, {
        class: _normalizeClass(["software_icon", $data.iconAnim[index]]),
        src: _ctx.imgUrl(software.picture),
        alt: software.name
      }, null, 8, ["class", "src", "alt"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(software.winV || software.macV || ""), 1), _createVNode(_component_o_s_tag, {
        value: software.os
      }, null, 8, ["value"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(software.name), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(software.slogan), 1)])])]),
      _: 2
    }, 1032, ["to", "target"])], 2);
  }), 128))], 2);
}
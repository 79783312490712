import ChatUpload from "./ChatUpload.vue";
export default {
  components: {
    ChatUpload
  },

  data() {
    return {
      msg: null,
      writing: false
    };
  },

  watch: {
    msg(val) {
      if (val != null && val.length) {
        if (!this.writing) {
          this.writing = true;
          this.sendStartWriting();
        }
      } else {
        if (this.writing) {
          this.writing = false;
          this.sendStopWriting();
        }
      }
    }

  },
  methods: {
    sendStartWriting() {
      let message = {
        type: this.CHAT_STATE,
        data: this.START_W,
        id: this.generateId()
      };
      this.$emit("sendState", message);
    },

    sendStopWriting() {
      let message = {
        type: this.CHAT_STATE,
        data: this.STOP_W,
        id: this.generateId()
      };
      this.$emit("sendState", message);
    },

    startUpload(data) {
      this.$emit("startUpload", data);
    },

    succUpload(data) {
      this.$emit("succUpload", data);
    },

    errorUpload(data) {
      this.$emit("errorUpload", data);
    },

    send() {
      if (this.writing) {
        this.writing = false;
        this.sendStopWriting();
      }

      let messageStr = this.msg;
      this.msg = null;
      this.$refs.input.focus();

      if (this.isNotNull(messageStr)) {
        let message = {
          type: this.CHAT_TEXT,
          data: messageStr,
          id: this.generateId()
        };
        this.$emit("sendContent", message);
      }
    }

  },

  mounted() {
    this.$refs.input.focus();
  }

};
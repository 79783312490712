// import Div3D from "@/components/3DDiv.vue";
import OSTag from "@/components/OSTag.vue";
import { mapState } from "vuex";
export default {
  name: "SoftwareGrid",
  computed: { ...mapState(["openBlank"]),

    targetState() {
      if (this.openBlank) {
        return "_blank";
      } else {
        return "_self";
      }
    }

  },
  props: {
    value: {
      type: Array,
      default: () => {}
    },
    s: {
      type: Boolean,
      default: false
    },
    os: {
      type: Number,
      default: -1
    },
    isDelayAnim: {
      type: Boolean,
      default: true
    },
    isEnterAnim: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(val) {
      //强制让列表刷新,让列表刷新进入动画
      this.data = null;
      setTimeout(() => {
        this.softwareAnim = [];
        this.iconAnim = [];
        this.data = val;
        this.startAnim();
      }, 20);
    }

  },
  components: {
    // Div3D,
    OSTag
  },

  data() {
    return {
      data: this.value,
      sourceData: this.value,
      column: 0,
      softwareAnim: [],
      iconAnim: []
    };
  },

  methods: {
    dataSizeChange(width) {
      let column = 0;

      if (width <= 992) {
        column = 3;
      } else if (width <= 1259.98) {
        column = 4;
      } else if (width <= 1499.98) {
        column = 5;
      } else if (width >= 1500) {
        column = 6;
      }

      if (this.column != column) {
        this.column = column;

        if (this.data != null && this.data.length > 0) {
          if (this.sourceData.length < column * 2) {
            this.data = this.sourceData.slice(0, column);
          } else {
            this.data = this.sourceData.slice(0, column * 2);
          }
        }
      }
    },

    toUrl(data) {
      let url = "";

      if (data.type == 1) {
        url = "/software/" + data.id;
      } else if (data.type == 2) {
        url = "/software/collection/" + data.id;
      }

      if (this.os != -1 && this.os != this.ALL) {
        if (this.os == this.WIN && data.os == this.ALL) {
          url += "?os=win";
        } else if (this.os == this.MAC && data.os == this.ALL) {
          url += "?os=mac";
        }
      }

      return url;
    },

    startAnim() {
      if (this.isEnterAnim) {
        let softwareA = [];
        let iconA = [];
        this.$nextTick(() => {
          if (this.data) {
            for (let index = 0; index < this.data.length; index++) {
              let delayKey = "delay-" + index;
              let sAnim = {
                enter_animation: true
              };
              sAnim[delayKey] = true;
              softwareA.push(sAnim);
              let iAnim = {
                icon_animation: true
              };
              iAnim[delayKey] = true;
              iconA.push(iAnim);
            }

            this.softwareAnim = softwareA;
            this.iconAnim = iconA;
          }
        });
      }
    }

  },

  created() {
    if (!this.s) {
      this.dataSizeChange(document.body.clientWidth);
    }

    this.startAnim();
  }

};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main_header_layout"
};
const _hoisted_2 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_main_header = _resolveComponent("main-header");

  const _component_yz_chat = _resolveComponent("yz-chat");

  const _component_router_view = _resolveComponent("router-view");

  const _component_footer_view = _resolveComponent("footer-view");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_main_header)]), _createVNode(_component_yz_chat), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)]), _createVNode(_component_footer_view)]);
}
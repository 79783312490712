export default {
  props: {
    hover: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  computed: {
    w() {
      if (this.width <= 0) {
        return "100%";
      } else {
        return this.width + "px";
      }
    },

    h() {
      if (this.height <= 0) {
        return "100%";
      } else {
        return this.height + "px";
      }
    }

  },

  data() {
    return {
      enterState: false,
      leaveState: false
    };
  },

  methods: {
    enter() {
      if (!this.enterState) {
        this.enterState = true;
        this.leaveState = false;
      }
    },

    leave() {
      if (!this.leaveState) {
        this.leaveState = true;
        this.enterState = false;
      }
    }

  }
};
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "operation"
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_text_view = _resolveComponent("text-view");

  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, {
    "custom-class": "resouce_content_dialog",
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.dialogVisible = $event),
    "show-close": false,
    width: $options.dialogWidth
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = (...args) => $options.visit && $options.visit(...args)),
      href: $data.link,
      target: "_blank",
      class: "to_but"
    }, "访问网站", 8, _hoisted_2), [[_vShow, _ctx.isNotNull($data.link)]])]), _createElementVNode("div", {
      class: _normalizeClass({
        s: $data.size == 1
      })
    }, [_createVNode(_component_text_view, {
      value: $data.contetn,
      onTitleLink: $options.visit
    }, null, 8, ["value", "onTitleLink"])], 2)]),
    _: 1
  }, 8, ["modelValue", "width"]);
}
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mask"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "viewer_layout",
    onClick: _cache[0] || (_cache[0] = $event => $data.detailsDialog = false)
  }, [_withDirectives(_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    class: "showImg",
    src: $data.showImg
  }, null, 8, _hoisted_2)], 512), [[_vShow, $data.detailsDialog]])]);
}
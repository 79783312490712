import api from "@/api/software";
import SoftwareGroup from "./SoftwareGrid.vue";
import YzNav from "@/components/YzNav.vue";
import YzRadio from "@/components/YzRadio.vue";
export default {
  components: {
    SoftwareGroup,
    YzRadio,
    YzNav
  },

  data() {
    return {
      data: null,
      category: null,
      pagination: {
        size: 20,
        total: 0
      },
      os: [{
        id: this.ALL,
        title: "全部"
      }, {
        id: this.WIN,
        title: "windows"
      }, {
        id: this.MAC,
        title: "mac OS"
      }],
      curOS: parseInt(this.$route.query.os) || this.ALL
    };
  },

  watch: {
    curOS() {
      this.toRouter();
    }

  },
  methods: {
    toRouter(pager = null) {
      let pagerNumber = "";

      if (pager) {
        pagerNumber = "/" + pager;
      } else {
        pagerNumber = "/" + this.$route.params.pager;
      }

      if (this.curOS != 0) {
        this.$router.replace({
          path: "/software/all/" + this.$route.params.id + pagerNumber,
          query: {
            os: this.curOS
          }
        });
      } else {
        this.$router.replace("/software/all/" + this.$route.params.id + pagerNumber);
      }
    },

    getSoftware() {
      let params = {
        category: this.$route.params.id,
        size: this.pagination.size,
        page: this.$route.params.pager
      };

      if (this.curOS != this.ALL) {
        params.os = this.curOS;
      }

      api.getSoftware(params, res => {
        this.pagination.total = res.count;
        this.data = res.results;
      });
    },

    getCategory() {
      api.getSoftwareCategory(res => {
        this.category = res.map(e => {
          return {
            id: e.id,
            url: "/software/all/" + e.id + "/1",
            title: e.name
          };
        });
      });
    },

    currentPageChange(number) {
      this.toRouter(number);
    }

  },

  created() {
    this.getCategory();
    this.getSoftware();
    this.$watch(() => this.$route, () => {
      if (this.$route.name == "softwareAll") {
        this.curOS = parseInt(this.$route.query.os) || this.ALL;
        this.getSoftware();
      }
    });
  }

};
import api from "@/api/resource";
import YzLike2 from "@/components/YzLike2.vue";
import ResouceContentDialog from "./ResouceContentDialog.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  name: "ResourceGrid",
  components: {
    YzLike2,
    ResouceContentDialog
  },
  props: {
    value: {
      type: Array,
      default: () => {}
    },
    category: {
      type: Array,
      default: () => {}
    }
  },
  watch: {
    value(val) {
      //强制让列表刷新,让列表刷新进入动画
      this.data = null;
      setTimeout(() => {
        this.softwareAnim = [];
        this.iconAnim = [];
        this.data = val;
        this.$nextTick(() => {
          for (let index = 0; index < this.data.length; index++) {
            let delayKey = "delay-" + index;
            let sAnim = {
              enter_animation: true
            };
            sAnim[delayKey] = true;
            this.softwareAnim.push(sAnim);
            let iAnim = {
              icon_animation: true
            };
            iAnim[delayKey] = true;
            this.iconAnim.push(iAnim);
          }
        });
      }, 20);
    }

  },

  data() {
    return {
      data: this.value,
      sourceData: this.value,
      column: 0,
      softwareAnim: [],
      iconAnim: []
    };
  },

  methods: {
    link(data) {
      if (this.isNotNull(data.content)) {
        return "javascript:;";
      }

      return data.link;
    },

    linkTarget(data) {
      if (this.isNull(data.content) && this.isNotNull(data.link)) {
        return "_blank";
      }

      return null;
    },

    label(data) {
      if (this.isNotNull(data)) {
        return data.split(" ");
      } else {
        return null;
      }
    },

    longLike(id) {
      api.resourceLike(id, 10);
      ElMessage({
        message: "感谢您的爆赞",
        type: "success"
      });
    },

    like(id) {
      api.resourceLike(id);
    },

    toResource(data) {
      if (this.isNotNull(data.content)) {
        this.$refs.resouceContentDialog.open(data.id, data.content, data.link, data.size);
      } else {
        api.count(data.id);
      }
    }

  }
};
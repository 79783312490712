export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    type: {
      type: Number,
      default: 1
    }
  }
};
export default {
  name: "VersionsSwitch",
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      curOS: -1,
      switchLeft: false,
      textAnimationL: false,
      infoAnimationL: true,
      infoAnimationR: false,
      win: null,
      mac: null,
      infoTopFloor: false,
      lastChoiceMac: null,
      lastChoiceWin: null,
      lastSwitch: 0,
      all: false
    };
  },

  methods: {
    importData(val) {
      if (val != null) {
        let isMac = false;

        if (val.mac != null && val.mac.length > 0) {
          this.mac = val.mac;
          isMac = true;
          this.lastChoiceMac = 0;
        }

        if (val.win != null && val.win.length > 0) {
          this.win = val.win;
          isMac = false;
          this.lastChoiceWin = 0;
        }

        if (this.win != null && this.mac != null) {
          this.all = true;
        }

        if (this.$route.query.os != null) {
          if (this.$route.query.os == "win") {
            if (this.win != null) {
              this.leftAnimation();
            } else {
              this.rightAnimation();
            }
          } else if (this.$route.query.os == "mac") {
            if (this.mac != null) {
              this.rightAnimation();
            } else {
              this.leftAnimation();
            }
          }
        } else {
          if (isMac) {
            this.rightAnimation();
          } else {
            this.leftAnimation();
          }
        }

        this.choice(0);
      }
    },

    choice(index) {
      if (index != null) {
        if (this.curOS == this.WIN) {
          this.lastChoiceWin = index;
          this.$emit("versionChange", {
            type: this.WIN,
            versions: this.choiceVersions(this.win, index)
          });
        } else {
          this.lastChoiceMac = index;
          this.$emit("versionChange", {
            type: this.MAC,
            versions: this.choiceVersions(this.mac, index)
          });
        }
      }
    },

    choiceVersions(data, index) {
      for (const item of data) {
        item.isChoice = false;
      }

      data[index].isChoice = true;
      return data[index];
    },

    switchOS() {
      if (this.all) {
        let now = Date.now();

        if (now - this.lastSwitch > 1500) {
          this.lastSwitch = now;

          if (this.curOS == this.WIN) {
            this.$router.push(this.$route.path + "?os=mac");
            this.rightAnimation();
          } else {
            this.$router.push(this.$route.path + "?os=win");
            this.leftAnimation();
          }

          if (this.curOS == this.WIN) {
            this.choice(this.lastChoiceWin);
          } else {
            this.choice(this.lastChoiceMac);
          }
        }
      }
    },

    leftAnimation() {
      this.infoTopFloor = false;
      setTimeout(() => {
        this.switchLeft = true;
        this.infoAnimationR = false;
        this.infoAnimationL = true;
      }, 500);
      setTimeout(() => {
        this.infoTopFloor = true;
      }, 1500);
      this.curOS = this.WIN;
    },

    rightAnimation() {
      this.infoTopFloor = false;
      setTimeout(() => {
        this.switchLeft = false;
        this.infoAnimationL = false;
        this.infoAnimationR = true;
      }, 500);
      setTimeout(() => {
        this.infoTopFloor = true;
      }, 1500);
      this.curOS = this.MAC;
    }

  }
};
import { mapState } from "vuex";
export default {
  name: "QuestionView",
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  computed: { ...mapState(["winQuestion", "macQuestion"])
  },
  watch: {
    type(val) {
      if (val == this.WIN) {
        this.value = this.winQuestion;
      } else {
        this.value = this.macQuestion;
      }
    },

    winQuestion() {
      if (this.type == this.WIN) {
        this.value = this.winQuestion;
      } else {
        this.value = this.macQuestion;
      }
    }

  },

  data() {
    return {
      value: []
    };
  },

  mounted() {
    if (this.type == this.WIN) {
      this.value = this.winQuestion;
    } else {
      this.value = this.macQuestion;
    }
  }

};
import index from '@/pages/goods/GoodsIndex.vue'

export default [
  {
    path: '/goods',
    name: 'goodsIndex',
    component: index,
    meta: {
      title: '原子商城 - 送货上门'
    }
  },
]
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "question_layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.value, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass({
        question: true,
        hover_question_win: $props.type == _ctx.WIN,
        hover_question_mac: $props.type == _ctx.MAC
      })
    }, [_createVNode(_component_router_link, {
      to: '/software/qa/' + item.id
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
      _: 2
    }, 1032, ["to"])], 2);
  }), 128))]);
}
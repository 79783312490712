import "core-js/modules/es.array.includes.js";
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    value(val) {
      this.support = [];
      this.enterAnimation = true;

      if (val != null) {
        if (this.isNotNull(val.os)) {
          if (val.type == this.WIN || val.type == this.ALL) {
            this.winSupport(val);
          } else if (val.type == this.MAC || val.type == this.ALL) {
            this.macSupport(val);
          }
        } else {
          this.unknown();
        }
      }

      setTimeout(() => {
        this.enterAnimation = false;
      }, 1100);
    }

  },

  data() {
    return {
      support: [],
      enterAnimation: false
    };
  },

  methods: {
    unknown() {
      this.support.push({
        title: "系统兼容未知",
        state: 3,
        tips: "<p>该软件我们还未做兼容性调查，暂时还不知道兼容情况如何。</p>"
      });
    },

    winSupport(data) {
      let support = [];
      support.push({
        title: "win 11",
        state: 1,
        tips: "<p>Windows 11是由微软公司，于2021年6月24日发布的最新桌面端操作系统。</p><p>Windows 11提供了许多创新功能，增加了新版开始菜单和输入逻辑等，支持与时代相符的混合工作环境。</p>"
      });
      support.push({
        title: "win 10",
        state: 1,
        tips: "<p>Windows 10是由微软公司，于2015年7月29日发布桌面端操作系统。</p><p>Windows 10 是目前使用人数最多的操作系统</p>"
      });
      support.push({
        title: "win 8",
        state: 1,
        tips: "<p>Windows 8是由微软公司，于2012年10月26日发布桌面端操作系统。</p>"
      });
      support.push({
        title: "win 7",
        state: 1,
        tips: " <p>Windows 8是由微软公司，于2009年10月22日发布桌面端操作系统。</p>"
      });
      support.push({
        state: 1,
        tips: "" + "<p>64位的软件<span class='tips'> 不可以 </span>在32位系统中运行。</p>" + "<p>32位的软件<span class='tips'> 可以 </span>在64位系统中运行。</p>" + "<p class='tips_title'>位数支持目前分三种情况：</p>" + "<p><span class='green'>支持64/32位：</span>支持32位、64位系统计算机，通常软件包里有两个32和64两个版本的安装包。</p>" + "<p><span class='green'>支持64位：</span>支持64位系统计算机。</p>" + "<p><span class='green'>支持32位：</span>支持32位、64位系统计算机。</p>" + "<p>查看系统类型: <span class='tips'>右键 > 我的电脑(此电脑) > 属性 > 系统类型</span></p>"
      });

      if (data.os == "all") {
        support[0].tips += "<p class='green'>该软件支持Windows 11</p>";
      } else {
        if (!data.os.includes("win11")) {
          support[0].state = 2;
          support[0].tips += "<p class='yellow'>未针对Windows 11系统开发，但Windows 软件一般向上兼容，在新版系统中都可以使用</p>";
        } else {
          support[0].tips += "<p class='green'>该软件支持Windows 11</p>";
        }
      }

      if (data.os == "all") {
        support[1].tips += "<p class='green'>该软件支持Windows 10</p>";
      } else {
        if (!data.os.includes("win10")) {
          support[1].state = 2;
          support[1].tips += "<p class='yellow'>未针对Windows 10系统开发，但Windows 软件一般向上兼容，在新版系统中都可以使用</p>";
        } else {
          support[1].tips += "<p class='green'>该软件支持Windows 10</p>";
        }
      }

      if (data.os == "all") {
        support[2].tips += "<p class='green'>该软件支持Windows 8</p>";
      } else {
        if (!data.os.includes("win8")) {
          support[2].state = 3;
          support[2].tips += "<p class='red'>该软件不支持Windows 8</p>";
        } else {
          support[2].tips += "<p class='green'>该软件支持Windows 8</p>";
        }
      }

      if (data.os == "all") {
        support[3].tips += "<p class='green'>该软件支持Windows 7</p>";
      } else {
        if (!data.os.includes("win7")) {
          support[3].state = 3;
          support[3].tips += "<p class='red'>该软件不支持Windows 7</p>";
        } else {
          support[3].tips += "<p class='green'>该软件支持Windows 7</p>";
        }
      }

      if (data.support.includes("64") && data.support.includes("32")) {
        support[4].title = "支持32/64位";
        support[4].state = 1;
      } else if (data.support.includes("64")) {
        support[4].title = "支持64位";
        support[4].state = 1;
      } else if (data.support.includes("32")) {
        support[4].title = "支持32位";
        support[4].state = 1;
      }

      this.support = support;
    },

    macSupport(data) {
      let support = [];
      support.push({
        title: "10 Mojave",
        state: 1,
        tips: "<p>macOS 10 Mojave 是苹果公司，于2018年6月5日发布桌面端操作系统。</p><p>macOS Mojave提升了深色模式和隐私保护设置，文件侧边栏开始支持完整元数据，并可以在Mac上添加和管理HomeKit设备。</p>"
      });
      support.push({
        title: "11 BigSur",
        state: 1,
        tips: "<p>macOS 11 Big Sur 是苹果公司，于2020年6月23日发布桌面端操作系统。</p><p>macOS 11 Big Sur 的界面采用了新的设计，Safari 浏览器、地图和消息也得到更新，并专门在隐私保护方面优化升级。</p>"
      });
      support.push({
        title: "12 Monterey",
        state: 1,
        tips: "<p>macOS 12 Monterey 是苹果公司，于2021年6月8日发布桌面端操作系统。</p><p>该版本更新带来了许多新功能，如：快捷指令、Mac /iPad 通用控制、低功耗模式延长电池寿命等。</p>"
      });
      let os10 = false;
      let os11 = false;
      let os12 = false;

      for (const str of data.os.split(" ")) {
        if (str.indexOf("10") == 0) {
          os10 = true;
        }

        if (str.indexOf("11") == 0) {
          os11 = true;
        }

        if (str.indexOf("12") == 0) {
          os12 = true;
        }
      }

      if (!os10) {
        support[1].state = 3;
        support[1].tips += "<p class='red'>该软件不支持macOS 10 Big Sur</p>";
      } else {
        support[1].tips += "<p class='green'>该软件支持macOS 10 Big Sur</p>";
      }

      if (!os11) {
        support[1].state = 3;
        support[1].tips += "<p class='red'>该软件不支持macOS 11 Big Sur</p>";
      } else {
        support[1].tips += "<p class='green'>该软件支持macOS 11 Big Sur</p>";
      }

      if (!os12) {
        support[2].state = 3;
        support[2].tips += "<p class='red'>该软件不支持WmacOS 12 Monterey</p>";
      } else {
        support[2].tips += "<p class='green'>该软件支持macOS 12 Monterey</p>";
      }

      support.push({
        tips: "<p>M1 指的是苹果公司于 2020 年发布的基于 ARM 架构自研的 M1 芯片（类似 Intel/AMD CPU）。</p>" + "<p class='tips_title'>ARM M1 应用目前分四种情况：</p>" + "<p><span class='iconfont icon-error grey tips-icon'></span><span class='grey'>未兼容 M1：</span>这种安装后无法使用！</p>" + "<p><span class='iconfont icon-succ green tips-icon'></span><span class='green'>原生 M1 运行：</span>这种安装后即可正常运行。</p>" + "<p><span class='iconfont icon-alert yellow tips-icon'></span><span class='yellow'>需装 Rosetta：</span>这种需要安装 Rosetta 才可正常运行。</p>" + "<p><span class='iconfont icon-alert red tips-icon'></span><span class='red'>需 Rosetta 转译：</span>这种需要安装 Rosetta 并且找到此应用<span class='tips'> 右键 > 显示简介</span>，勾选<span class='tips'> 使用 Rosetta 打开 </span>才可正常运行。</p>"
      });

      if (this.isNull(data.support) || data.support.includes("不")) {
        support[3].title = "不支持M1";
        support[3].state = 3;
      } else if (data.support == "M1") {
        support[3].title = "支持原生M1";
        support[3].state = 1;
      } else if (data.support.includes("装")) {
        support[3].title = "M1 需装 Rosetta";
        support[3].state = 2;
      } else if (data.support.includes("转译")) {
        support[3].title = "M1 需 Rosetta 转译";
        support[3].state = 4;
      }

      this.support = support;
    }

  }
};
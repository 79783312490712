import {
	HTTP_URL
} from "./api/url.js"
const common = {
	isNull: function (value) {
		if (value != null && value != undefined && (value.length > 0 || value.length == undefined)) {
			return false
		} else {
			return true
		}
	},
	isNotNull: function (value) {
		return !this.isNull(value)
	},
	text(value, placeholde = "") {
		if (this.isNull(value)) {
			return placeholde
		} else {
			return value
		}
	},
	toTime(time) {
		let d = new Date(time * 1000);
		let date =
			d.getFullYear() +
			"-" +
			(d.getMonth() + 1) +
			"-" +
			d.getDate() +
			" " +
			d.getHours() +
			":" +
			d.getMinutes();
		return date;
	},
	toTime2(time) {
		let d = new Date(time * 1000);
		let date =
			(d.getMonth() + 1) +
			"-" +
			d.getDate() +
			" " +
			d.getHours() +
			":" +
			d.getMinutes();
		return date;
	},
	toTime3(time) {
		let d = new Date(time * 1000);
		let date =
			d.getFullYear() +
			"-" +
			(d.getMonth() + 1) +
			"-" +
			d.getDate()
		return date;
	},
	deepCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	},
	imgUrl(path) {
		return HTTP_URL + path
	},
	WIN: 1,
	MAC: 2,
	ALL: 3,
	DELAY_MAX: 6,
	CHAT_NET: 0,
	CHAT_STATE: 1,
	CHAT_REVOKE: 2,
	CHAT_TEXT: 3,
	CHAT_PICTURE: 4,
	CHAT_VIDEO: 5,
	CHAT_GOODS: 6,
	CHAT_SOFTWARE: 7,
	CHAT_HARDWARE: 8,
	CHAT_ARTICLE: 9,
	CHAT_UPLOAD_STATE_PER: 1,
	CHAT_UPLOAD_STATE_SUCC: 2,
	CHAT_UPLOAD_STATE_ERROR: 4,
	CHAT_UPLOAD_STATE_CONFIRM: 5,
	START_W: 1,
	STOP_W: 2,
	EVENT_REFRESH_STATISTICS: 1,
	eventList: [],
	generateId() {
		return Date.now();
	},
	registerEvent(key, func) {
		this.eventList.push({
			key: key,
			func: func
		})
	},
	sendEvent(key) {
		this.eventList.forEach(element => {
			if (element.key === key) {
				element.func()
				return;
			}
		});
	},
	unregisterEvent(key) {
		for (let index = 0; index < this.eventList.length; index++) {
			const element = this.eventList[index];
			if (element.key === key) {
				this.eventList.splice(index, 1)
			}

		}
	},
	money(value) {
		if (value != null) {
			var n = Number(value)
			if (!isNaN(n)) {
				if (value > 0) {
					return value / 100
				} else {
					return 0
				}
			} else {
				return value
			}
		} else {
			return null
		}
	},
	urlData(data) {
		return encodeURIComponent(JSON.stringify(data).replace(/%/g,
			'%25'))
	},
	getUrlData(data) {
		return JSON.parse(decodeURIComponent(data))
	},
	easeInOutQuad(t, b, c, d) {
		t /= d / 2
		if (t < 1) {
			return c / 2 * t * t + b
		}
		t--
		return -c / 2 * (t * (t - 2) - 1) + b
	},
	easeInQuad(curtime, begin, end, duration) {
		let x = curtime / duration;
		let y = x * x;
		return begin + (end - begin) * y;
	},
	easeOutQuad(curtime, begin, end, duration) {
		let x = curtime / duration;
		let y = -x * x + 2 * x;
		return begin + (end - begin) * y;
	},
	checkEmail(value) {
		const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
		return regEmail.test(value)
	}
}
export default common

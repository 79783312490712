import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9d5e02be"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "filp_header_layout"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "software_name"
};
const _hoisted_4 = {
  class: "anchor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_main_header = _resolveComponent("main-header");

  const _component_header_view = _resolveComponent("header-view");

  const _component_filp_3_d = _resolveComponent("filp-3-d");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_filp_3_d, {
    ref: "filp",
    hover: false
  }, {
    front: _withCtx(() => [_createVNode(_component_main_header, {
      type: $props.type
    }, null, 8, ["type"])]),
    bottom: _withCtx(() => [_createVNode(_component_header_view, null, {
      head: _withCtx(() => [_createElementVNode("div", {
        class: "header",
        onClick: _cache[0] || (_cache[0] = $event => $options.top())
      }, [_createElementVNode("img", {
        class: "software_icon",
        src: _ctx.imgUrl($props.value.picture)
      }, null, 8, _hoisted_2), _createElementVNode("h4", _hoisted_3, [_createElementVNode("span", null, _toDisplayString($props.value.name), 1), _createElementVNode("span", null, _toDisplayString($props.versions), 1)])])]),
      foot: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", {
        class: _normalizeClass({
          activeWin: $data.anchorActive == 1 && $props.type == _ctx.WIN,
          activeMac: $data.anchorActive == 1 && $props.type == _ctx.MAC,
          hover_active_win: $props.type == _ctx.WIN,
          hover_active_mac: $props.type == _ctx.MAC
        }),
        onClick: _cache[1] || (_cache[1] = $event => $options.anchor1())
      }, "软件介绍", 2), _createElementVNode("span", {
        class: _normalizeClass({
          activeWin: $data.anchorActive == 2 && $props.type == _ctx.WIN,
          activeMac: $data.anchorActive == 2 && $props.type == _ctx.MAC,
          hover_active_win: $props.type == _ctx.WIN,
          hover_active_mac: $props.type == _ctx.MAC
        }),
        onClick: _cache[2] || (_cache[2] = $event => $options.anchor2())
      }, "安装说明", 2), _createElementVNode("span", {
        class: _normalizeClass({
          activeWin: $data.anchorActive == 3 && $props.type == _ctx.WIN,
          activeMac: $data.anchorActive == 3 && $props.type == _ctx.MAC,
          hover_active_win: $props.type == _ctx.WIN,
          hover_active_mac: $props.type == _ctx.MAC
        }),
        onClick: _cache[3] || (_cache[3] = $event => $options.anchor3())
      }, "常见问题", 2)]), _createElementVNode("div", {
        class: _normalizeClass({
          head_download_but: true,
          downWin: $props.type == _ctx.WIN,
          downMac: $props.type == _ctx.MAC,
          hover_down_win: $props.type == _ctx.WIN,
          hover_down_mac: $props.type == _ctx.MAC
        }),
        onClick: _cache[4] || (_cache[4] = (...args) => $options.download && $options.download(...args))
      }, " 立刻下载 ", 2)]),
      _: 1
    })]),
    _: 1
  }, 512)]);
}
import YzSearch from "@/components/YzSearch.vue";
export default {
  components: {
    YzSearch
  },
  props: {
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      translateX1: 0,
      translateY1: 0,
      translateX2: 0,
      translateY2: 0,
      translateX3: 0,
      translateY3: 0,
      translateX4: 0,
      translateY4: 0,
      rotateY: 0,
      rotateX: 0,
      box: null,
      multipleY: 80,
      multipleX: 150
    };
  },

  methods: {
    mousemove(e) {
      setTimeout(() => {
        let x = -(e.clientY - this.box.y - this.box.height / 2);
        let y = e.clientX - this.box.x - this.box.width / 2;
        this.translateY1 = x / this.multipleY;
        this.translateX1 = y / this.multipleX;
        this.translateY2 = x / 50;
        this.translateX2 = y / 100;
        this.translateY3 = x / 40;
        this.translateX3 = y / 120;
        this.translateY4 = x / 70;
        this.translateX4 = y / 90;
        this.tY = x / 100;
        this.tX = y / 100;
      }, 200);
    },

    mouseenter() {},

    mouseleave() {}

  },

  mounted() {
    this.box = this.$refs.box.getBoundingClientRect();
  }

};
import { createApp } from 'vue'
import App from './App.vue'
import base from '../src/common/base'
import store from '../src/common/store'
import router from '../src/routers/router'

function addPrototye(source, target) {
  for (let key in target) {
    source.config.globalProperties[key] = target[key]
  }
}

const app = createApp(App)
addPrototye(app, base)
app.use(router)
app.use(store)
app.mount('#app')


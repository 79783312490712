export default {
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    modelValue: {
      type: Number
    }
  },

  data() {
    return {
      cur: -1
    };
  },

  watch: {
    modelValue(val) {
      this.cur = val;
    }

  },
  methods: {
    choice(id) {
      if (this.cur != id) {
        this.$emit("update:modelValue", id);
        this.cur = id;
      }
    }

  },

  created() {
    this.cur = this.modelValue;
  }

};
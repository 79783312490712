import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3170d67a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header_layout"
};
const _hoisted_2 = {
  class: "container header_content"
};
const _hoisted_3 = {
  class: "head"
};
const _hoisted_4 = {
  class: "foot"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "head", {}, undefined, true)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "foot", {}, undefined, true)])])]);
}
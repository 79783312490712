import index from '@/pages/software/SoftwareIndex.vue'
import details from '@/pages/software/SoftwareDetails.vue'
import question from '@/pages/software/QuestionDetails.vue'
import all from '@/pages/software/SoftwareAll.vue'
import collection from '@/pages/software/SoftwareCollection.vue'

export default [
  {
    path: '/',
    name: 'softwareIndex',
    component: index,
    meta: {
      title: '原子闪修 - 您身边的电脑专家'
    }
  }, {
    path: '/software/:id',
    name: 'software',
    component: details,
    meta: {
      special: true
    }
  }, {
    path: '/software/qa/:id',
    name: 'question',
    component: question
  }, {
    path: '/software/all/:id/:pager',
    name: 'softwareAll',
    component: all,
    meta: {
      title: '原子闪修 - 免费破译win、mac软件'
    }
  }, {
    path: '/software/collection/:id',
    name: 'softwareCollection',
    component: collection
  }
]
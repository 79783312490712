export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    os: {
      type: Number,
      default: 1
    }
  },
  watch: {
    value(val) {
      this.likeNum = val;
    }

  },

  data() {
    return {
      likeNum: 0,
      isLike: false,
      isLongLike: false,
      likeLong: false,
      likeLongTimer1: null,
      likeLongTimer2: null,
      likeClick: false,
      likestopState: false
    };
  },

  computed: {
    linkText() {
      if (this.isLike || this.isLongLike) {
        if (this.isLongLike) {
          return "已爆赞(" + this.likeNum + ")";
        } else {
          return "已赞(" + this.likeNum + ")";
        }
      } else {
        return "赞(" + this.likeNum + ")";
      }
    }

  },
  methods: {
    likestart() {
      if (!this.isLongLike) {
        this.likeClick = true;
        this.likeLongTimer1 = setInterval(() => {
          this.likeLong = true;
          clearInterval(this.likeLongTimer1);
        }, 600);
        this.likeLongTimer2 = setInterval(() => {
          if (this.likeClick) {
            this.likestopState = true;
            clearInterval(this.likeLongTimer1);
            clearInterval(this.likeLongTimer2);
            this.longLike();
            this.likeLong = false;
            this.likeClick = false;
          }
        }, 1600);
      }
    },

    likestop() {
      if (this.likeClick) {
        this.like();
        this.likestopState = true;
        clearInterval(this.likeLongTimer1);
        clearInterval(this.likeLongTimer2);
        this.likeLong = false;
        this.likeClick = false;
      }
    },

    longLike() {
      if (!this.isLongLike) {
        let increment = 0;
        this.isLongLike = true;

        for (let index = 1; index <= 10; index++) {
          increment += 80;
          let time = this.easeOutQuad(increment, 0, 800, 800);
          setTimeout(() => {
            this.likeNum++;

            if (index == 10) {
              this.$emit("longLike");
            }
          }, time);
        }
      }
    },

    like() {
      if (!this.isLike && !this.isLongLike) {
        this.likeNum++;
        this.isLike = true;
        this.$emit("like");
      }
    }

  },

  created() {
    this.likeNum = this.value;
  }

};
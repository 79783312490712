import YzLoading from "@/components/loading/YzLoading1.vue";
export default {
  props: {
    value: {
      type: String
    },
    state: {
      type: Number
    }
  },
  components: {
    YzLoading
  },
  computed: {
    imgSrc() {
      if (this.value.length > 8) {
        if (this.value.slice(0, 8) == "tempChat") {
          return this.imgUrl(this.value);
        } else {
          return this.value;
        }
      } else {
        return null;
      }
    }

  },
  methods: {
    see() {
      this.$emit("see", this.imgSrc);
    }

  }
};
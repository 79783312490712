import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0240d6d7"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "yz_nav_layout"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item.id
    }, [_createVNode(_component_router_link, {
      to: item.url,
      class: _normalizeClass({
        active: item.url == $data.curPath
      }),
      replace: $props.replace
    }, {
      default: _withCtx(() => [_ctx.isNotNull($props.name) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.text(item)), 1)) : _renderSlot(_ctx.$slots, "default", {
        key: 1,
        data: item
      }, undefined, true)]),
      _: 2
    }, 1032, ["to", "class", "replace"])]);
  }), 128))]);
}
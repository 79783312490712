import api from "@/api/article";
import TextView from "@/components/TextView.vue";
import YzLike2 from "@/components/YzLike2.vue";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
export default {
  components: {
    TextView,
    YzLike2
  },

  data() {
    return {
      data: {
        title: null,
        content: null
      }
    };
  },

  methods: {
    getDatails() {
      api.getDataDetails(this.$route.params.id, res => {
        this.data = res;
        document.title = res.title;
      });
    },

    to() {
      this.getDatails();
    },

    longLike(id) {
      api.like(id, 10);
      ElMessage({
        message: "感谢您的爆赞",
        type: "success"
      });
    },

    like(id) {
      api.like(id);
    }

  },

  created() {
    this.getDatails();
  }

};
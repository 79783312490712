import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2027947c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      but_border: true,
      hover_like_win: $props.os == 1,
      hover_like_mac: $props.os == 2,
      to_win3: $props.os == 1,
      to_mac3: $props.os == 2
    }),
    onMousedown: _cache[0] || (_cache[0] = (...args) => $options.likestart && $options.likestart(...args)),
    onMouseup: _cache[1] || (_cache[1] = (...args) => $options.likestop && $options.likestop(...args)),
    onMouseout: _cache[2] || (_cache[2] = (...args) => $options.likestop && $options.likestop(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass({
      icon_left: true,
      shake: $data.likeLong,
      iconfont: true,
      'icon-like': true
    })
  }, null, 2), _createElementVNode("span", _hoisted_1, _toDisplayString($options.linkText), 1)], 34);
}
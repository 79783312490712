import {
	GET,
} from '../common/api/service.js'
import progress from '@/common/nprogress'

const URL = {
	preview: 'service/information/preview/',
	software: "service/information/apiSoftware/",
	softwareCategory: 'service/information/apiSoftwareCategory/',
	softwareLike: "service/information/softwareLike/",
	macDownload: "service/information/macDownload/",
	windowsDownload: "service/information/windowsDownload/",
	softwareQuestion: 'service/information/apiSoftwareQuestion/',
	countNumber: 'service/information/countSoftwareNumber/',
}
const api = {
	getPreview(succ) {
		let requestOptions = {
			path: URL.preview,
			succ: succ
		}
		GET(requestOptions)
	},
	getSoftware(params, succ) {
		let requestOptions = {
			path: URL.software,
			params: params,
			isLoading: true,
			succ: succ,
			complete: () => {
				progress.done()
			}
		}
		GET(requestOptions)
	},
	softwareLike(id, num = 1) {
		let path = null
		if (num > 1) {
			path = URL.softwareLike + id + "/?num=" + num
		} else {
			path = URL.softwareLike + id + "/"
		}
		let requestOptions = {
			path: path,
		}
		GET(requestOptions)
	},
	macDownload(id, vId) {
		let requestOptions = {
			path: URL.macDownload + id + "/",
			params: {
				id: vId
			}
		}
		GET(requestOptions)
	},
	windowsDownload(id, vId) {
		let requestOptions = {
			path: URL.windowsDownload + id + "/",
			params: {
				id: vId
			}
		}
		GET(requestOptions)
	},
	getSoftwareCategory(succ) {
		let requestOptions = {
			path: URL.softwareCategory,
			succ: succ,
			complete: () => {
				progress.done()
			}
		}
		GET(requestOptions)
	},
	getSoftwareDetails(id, succ) {
		let requestOptions = {
			path: URL.software + id + "/",
			isLoading: true,
			succ: succ,
			complete: () => {
				progress.done()
			}
		}
		GET(requestOptions)
	},
	getSoftwareQuestionDetails(id, succ) {
		let requestOptions = {
			path: URL.softwareQuestion + id + "/",
			isLoading: true,
			succ: succ,
			complete: () => {
				progress.done()
			}
		}
		GET(requestOptions)
	},
	getSoftwareQuestion(succ) {
		let requestOptions = {
			path: URL.softwareQuestion,
			succ: succ
		}
		GET(requestOptions)
	},
	getCountNumber(succ) {
		let requestOptions = {
			path: URL.countNumber,
			succ: succ
		}
		GET(requestOptions)
	},
}
export default api

import HeaderView from "./HeaderView.vue";
import Filp3D from "../3DFlip.vue";
import YzSwitch from "@/components/YzSwitch.vue";
import { useRouter } from "vue-router";
import { mapState, mapMutations } from "vuex";
export default {
  computed: { ...mapState(["openBlank"])
  },
  components: {
    HeaderView,
    Filp3D,
    YzSwitch
  },
  props: {
    type: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      navTarget: 1,
      isOpenBlank: true,
      showSwitchOpenBlank: false
    };
  },

  watch: {
    isOpenBlank(newData, oldData) {
      if (newData != oldData) {
        this.setOpenBlank(newData);
      }
    }

  },
  methods: { ...mapMutations(["setOpenBlank"]),

    openBlankChange(val) {
      if (this.isOpenBlank != val) {
        this.setOpenBlank(val);
      }
    }

  },

  created() {
    this.isOpenBlank = this.openBlank;
    const router = useRouter();
    this.$watch(() => router.currentRoute.value.name, to => {
      this.showSwitchOpenBlank = false;

      if (to == "softwareIndex" || to == "software" || to == "question" || to == "softwareAll" || to == "softwareCollection") {
        this.navTarget = 1;

        if (to != "question") {
          this.showSwitchOpenBlank = true;
        }
      } else if (to == "articleIndex" || to == "article") {
        this.navTarget = 2;
      } else if (to == "resourceAll" || to == "resource") {
        this.navTarget = 3;
      } else if (to == "goodsIndex") {
        this.navTarget = 4;
      }
    }, {
      immediate: true,
      deep: true
    });
  }

};
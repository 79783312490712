import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-df64027c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "resource_layout"
};
const _hoisted_2 = ["title"];
const _hoisted_3 = ["onClick", "href", "target"];
const _hoisted_4 = {
  class: "icon_layout"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "content_layout"
};
const _hoisted_7 = {
  class: ""
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  class: "sub_title"
};
const _hoisted_10 = {
  class: "row tips_layout"
};
const _hoisted_11 = {
  class: "tag_layout"
};
const _hoisted_12 = {
  class: "label_layout"
};
const _hoisted_13 = {
  class: "end_layout"
};
const _hoisted_14 = {
  class: "share_tag"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  const _component_yz_like2 = _resolveComponent("yz-like2");

  const _component_resouce_content_dialog = _resolveComponent("resouce-content-dialog");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: _normalizeClass($data.softwareAnim[index])
    }, [_createElementVNode("div", {
      title: item.describe,
      class: "resource"
    }, [_createElementVNode("a", {
      class: "row resource_link",
      onClick: $event => $options.toResource(item),
      href: $options.link(item),
      target: $options.linkTarget(item)
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
      class: "icon_bg",
      src: _ctx.imgUrl(item.picture)
    }, null, 8, _hoisted_5), _createVNode(_component_el_image, {
      class: _normalizeClass(["icon", $data.iconAnim[index]]),
      src: _ctx.imgUrl(item.picture),
      alt: item.title
    }, null, 8, ["class", "src", "alt"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("h4", _hoisted_8, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_9, _toDisplayString(item.describe), 1)]), _createElementVNode("div", _hoisted_10, [_withDirectives(_createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.category2Text, (tag, index) => {
      return _openBlock(), _createElementBlock("span", {
        class: "tag",
        key: index
      }, _toDisplayString(tag), 1);
    }), 128))], 512), [[_vShow, item.category2Text != null]]), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.label(item.label), (tag, index) => {
      return _openBlock(), _createElementBlock("span", {
        key: index
      }, _toDisplayString(tag), 1);
    }), 128))])])])], 8, _hoisted_3), _createElementVNode("div", _hoisted_13, [_withDirectives(_createElementVNode("span", _hoisted_14, "来自网友的分享", 512), [[_vShow, item.share]]), _createVNode(_component_yz_like2, {
      value: item.like,
      onLike: $event => $options.like(item.id),
      onLongLike: $event => $options.longLike(item.id)
    }, null, 8, ["value", "onLike", "onLongLike"])])], 8, _hoisted_2)], 2);
  }), 128)), _createVNode(_component_resouce_content_dialog, {
    ref: "resouceContentDialog"
  }, null, 512)]);
}
import TextView from "@/components/TextView.vue";
import api from "@/api/software";
export default {
  components: {
    TextView
  },

  data() {
    return {
      data: {
        title: null,
        content: null
      }
    };
  },

  methods: {
    getDatails() {
      api.getSoftwareQuestionDetails(this.$route.params.id, res => {
        this.data = res;
        document.title = res.title;
      });
    },

    to() {
      this.getDatails();
    }

  },

  created() {
    this.getDatails();
  }

};
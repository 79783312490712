import "core-js/modules/es.array.includes.js";
import api from "@/api/resource";
import ResourceGrid from "./ResourceGrid.vue";
import ProposalDialog from "./ProposalDialog.vue";
import RecommendView from "./RecommendView.vue";
export default {
  components: {
    ResourceGrid,
    ProposalDialog,
    RecommendView
  },

  data() {
    return {
      category: [],
      data: [],
      recommend: [],
      sourceData: [],
      categoryId: null,
      categoryId2: -1,
      categoryChild: [],
      category2Name: 'name'
    };
  },

  methods: {
    getCategory() {
      api.getResourceCategory(this.categoryId, res => {
        this.category = res.category.map(e => {
          e.url = "/res/all/" + e.id;
          return e;
        });

        if (this.categoryId == null && this.category.length > 0) {
          this.categoryId = this.category[0].id;
          this.categoryChild = this.handleCategory2(this.category[0]);
        } else {
          this.categoryChild = this.handleCategory2(this.category.find(e => {
            return e.id == this.categoryId;
          }));
        }

        this.sourceData = this.handleData(res.resource);
        this.data = this.filterCategory2(this.sourceData);
        this.recommend = res.recommend;
      });
    },

    switchCategory() {
      this.categoryChild = this.handleCategory2(this.category.find(e => {
        return e.id == this.categoryId;
      }));
      this.categoryId2 = -1;
      this.getData();
    },

    getData() {
      api.getResource({
        category1: this.categoryId
      }, res => {
        this.sourceData = this.handleData(res);
        this.data = this.filterCategory2(this.sourceData);
      });
    },

    handleCategory2(data) {
      if (data.child != null && data.child.length > 0) {
        let category = data.child.map(e => {
          e.url = '/res/all/' + this.categoryId + '-' + e.id;
          return e;
        });
        category.unshift({
          name: "全部",
          url: '/res/all/' + this.categoryId,
          id: -1
        });
        return category;
      }

      return [];
    },

    handleData(data) {
      if (data != null) {
        return data.map(e => {
          if (e.category22 != null && e.category22.length > 0) {
            e.category2Text = e.category22.map(category => {
              return this.categoryChild.find(c => {
                return c.id == category;
              }).name;
            });
          } else {
            e.category2Text = null;
          }

          return e;
        });
      } else {
        return [];
      }
    },

    filterCategory2(data) {
      if (this.categoryId2 != null && this.categoryId2 != -1) {
        return data.filter(e => {
          return e.category22.includes(this.categoryId2);
        });
      }

      return data;
    },

    parseUrl(url) {
      if (this.isNotNull(url)) {
        let params = url.split("-");

        if (params.length == 1) {
          this.categoryId = parseInt(params[0]);
          this.categoryId2 = -1;
        } else if (params.length == 2) {
          this.categoryId = parseInt(params[0]);
          this.categoryId2 = parseInt(params[1]);
        }
      } else {
        this.categoryChild = null;
        this.categoryId = null;
        this.categoryId2 = -1;
      }
    },

    share() {
      this.$refs.proposal.open();
    }

  },

  created() {
    this.parseUrl(this.$route.params.id);
    this.getCategory();
    this.$watch(() => this.$route, to => {
      if (to.name == "resourceAll") {
        let category1 = this.categoryId;
        this.parseUrl(to.params.id);

        if (category1 != this.categoryId) {
          this.switchCategory();
        } else {
          this.data = this.filterCategory2(this.sourceData);
        }
      }
    });
  }

};
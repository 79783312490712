import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_switch = _resolveComponent("el-switch");

  return _openBlock(), _createBlock(_component_el_switch, {
    "v-model": $props.value,
    "active-color": "#2aa5fc",
    class: "win_active",
    "active-text": "新窗口打开"
  }, null, 8, ["v-model"]);
}
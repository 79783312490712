import ChatText from "./content/ChatText.vue";
import ChatWriting from "./content/ChatWriting.vue";
import ChatImg from "./content/ChatImg.vue";
import ChatViewer from "./ChatViewer.vue";
export default {
  components: {
    ChatText,
    ChatWriting,
    ChatImg,
    ChatViewer
  },
  props: {
    value: {
      type: Array,
      default: () => {}
    },
    head: {
      type: String
    }
  },

  data() {
    return {};
  },

  methods: {
    seeImg(data) {
      this.$refs.viewer.open(data);
    },

    setMessage() {
      this.scrollEnd();
    },

    scrollEnd() {
      this.$nextTick(() => {
        document.getElementById("scroll_bottom").scrollIntoView({
          block: "end",
          inline: "nearest"
        });
      });
    }

  }
};
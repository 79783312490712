import Filp3D from "../3DFlip.vue";
import MainHeader from "./MainHeader.vue";
import HeaderView from "./HeaderView.vue";
import { scrollTo } from "@/common/scrollTo.js";
export default {
  emits: ["anchor", "download"],
  components: {
    Filp3D,
    MainHeader,
    HeaderView
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: null,
          picture: null
        };
      }
    },
    versions: {
      type: String
    },
    type: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      anchorActive: -1
    };
  },

  methods: {
    enter() {
      this.$refs.filp.enter();
    },

    leave() {
      this.$refs.filp.leave();
    },

    anchor1() {
      this.$emit("anchor", 1);
    },

    anchor2() {
      this.$emit("anchor", 2);
    },

    anchor3() {
      this.$emit("anchor", 3);
    },

    setAnchor(index) {
      if (this.anchorActive != index) {
        this.anchorActive = index;
      }
    },

    top() {
      scrollTo(0);
    },

    download() {
      this.$emit("download");
    }

  }
};
import axios from 'axios'
import {
	HTTP_URL
} from "./url.js"
import auth from './auth.js'

const SUCCESS = "000000"
const AUTH_FAIL = "000001"
const DEFAULT_TOAST = true
const DEFAULT_LOADING = false
const DEFAULT_LOADING_TITLE = ""

function _isSuccess(code) {
	return code == SUCCESS
}

function _isAuthFail(code) {
	return code == AUTH_FAIL
}

function _getData(res) {
	return res.data.data
}

function _getMessage(res) {
	return res.data.message
}

function _getCode(res) {
	return res.data.code
}


function _request(method, path, parameter, header) {
	let config = {
		url: path,
		method: method,
		baseURL: HTTP_URL,
		headers: {
			...header,
			'token': auth.getToken()
		},
		withCredentials: true,
	}
	if (method == "GET") {
		config.params = parameter
	} else if (method == "POST" || method == "PUT" || method == "PATCH" || method == "DELETE") {
		config.data = parameter
	}
	return axios.request(config)
}

function _callBack(source, funcName, targetData = null) {
	if (source != undefined &&
		funcName in source &&
		source[funcName] instanceof Function) {
		if (source.bind != undefined && source.bind != null) {
			source[funcName].call(source.bind, targetData)
		} else {
			source[funcName](targetData)
		}
	}
}

function _awaitResult(request, requestOptions) {
	const isToast = requestOptions.isToast || DEFAULT_TOAST
	const isLoading = (requestOptions.isLoading == true || (requestOptions.loadingTitle != undefined && requestOptions
		.loadingTitle != null && requestOptions.loadingTitle.length > 0)) || DEFAULT_LOADING
	const loadingTitle = requestOptions.loadingTitle || DEFAULT_LOADING_TITLE

	request.then((res) => {
		const data = _getData(res)
		const code = _getCode(res)
		const message = _getMessage(res)

		console.log(loadingTitle)
		if (isLoading) {
			// uni.showLoading({
			// 	title: loadingTitle
			// });
		}

		// let existToast = false
		if (_isSuccess(code)) {
			if (isLoading) {
				// uni.hideLoading()
			}
			_callBack(requestOptions, 'succ', data)
		} else {
			if (_isAuthFail(code)) {
				console.error('验证失败')
			} else {
				if (message != undefined && message != null && message.length > 0) {
					_callBack(requestOptions, 'fail', message)
					if (isLoading) {
						// uni.hideLoading()
					}
					if (isToast) {
						// existToast = true

						// uni.showToast({
						// 	icon: 'none',
						// 	title: message,
						// 	duration: 1500
						// });
					}
				} else {
					_callBack(requestOptions, 'fail')
					// if (isLoading) {
					// 	uni.hideLoading()
					// }
					if (isToast) {
						// existToast = true

						// 	uni.showToast({
						// 		icon: 'none',
						// 		title: '操作失败',
						// 		duration: 1000
						// 	});
					}
				}
			}
		}
		_callBack(requestOptions, 'complete')
	}).catch(err => {
		console.error(err)
		_callBack(requestOptions, 'failure')
		_callBack(requestOptions, 'complete')
		if (isLoading) {
			// 	uni.hideLoading()
		}
	})
}

// requestOptions = {
// 	path: '',
// 	params: {},
// 	isLoading: false,	//请求中是否显示loading框
// 	loadingTitle: "提交中", //loading框title
// 	isToast: true, //失败情况是否显示toast提示
// 	header: [], //请求头
// 	succ: (res) => {}, //业务成功,返回业务数据
// 	fail: (msg) => {}, //业务失败,返回错误消息
// 	failure: () => {}, //网络失败
// 	complete: () => {} //请求完成	
// }

function GET(requestOptions) {
	_awaitResult(_request("GET", requestOptions.path, requestOptions.params, requestOptions.header),
		requestOptions)
}

function POST(requestOptions) {
	_awaitResult(_request("POST", requestOptions.path, requestOptions.params, requestOptions.header), requestOptions)
}

function UPLOAD(requestOptions) {
	_awaitResult(_request("POST", requestOptions.path, requestOptions.params, {
		'content-type': 'application/x-www-form-urlencoded',
		...requestOptions.header
	}), requestOptions)
}

function PUT(requestOptions) {
	_awaitResult(_request("PUT", requestOptions.path, requestOptions.params, requestOptions.header), requestOptions)
}
function PATCH(requestOptions) {
	_awaitResult(_request("PATCH", requestOptions.path, requestOptions.params, requestOptions.header), requestOptions)
}
function DELETE(requestOptions) {
	_awaitResult(_request("DELETE", requestOptions.path, requestOptions.params, requestOptions.header), requestOptions)
}

export {
	GET,
	POST,
	UPLOAD,
	PUT,
	PATCH,
	DELETE
}

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-179f3458"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "perspective"
};
const _hoisted_2 = {
  class: "front"
};
const _hoisted_3 = {
  class: "bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass({
      box: true,
      hover_box: $props.hover,
      filp_enter: !$props.hover && $data.enterState,
      filp_leave: !$props.hover && $data.leaveState
    }),
    style: _normalizeStyle({
      width: $options.w,
      height: $options.h
    })
  }, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "front", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "bottom", {}, undefined, true)])], 6)]);
}
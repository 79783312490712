import progress from '@/common/nprogress'
import { createRouter, createWebHistory } from 'vue-router'
import software from "./software";
import goods from "./goods";
import resource from "./resource";
import article from "./article";

if (process.env.NODE_ENV != "development") {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?b139019be67a1991c6a4bd54d65b3c8f";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
}

const routes = [].concat(
  software,
  goods,
  resource,
  article
)
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
})

router.beforeEach((to, from, next) => {
  // 软件详情页面需要完成一个特殊操作
  // 切换win或mac在地址栏上记录，并且不启动进度条
  // 所以这里判断to和from是同一个地址，并且有详情页面特殊的mate，special时不启动进度条
  if (!(to.path == from.path && to.meta.special)) {
    progress.start()
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to) => {
  if (!(to.path.indexOf("/software") == 0 || to.fullPath == "/")) {
    progress.done()
  }
  if (process.env.NODE_ENV != "development") {
    window._hmt.push(['_trackPageview', to.fullPath])
  }
})

export default router
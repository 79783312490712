
import { createStore } from 'vuex'
import informationService from '../api/software.js'

const store = new createStore({
	state() {
		return {
			winQuestion: [], //win常见问题
			macQuestion: [], //mac常见问题
			openBlank: localStorage.getItem("openBlank") == null ? true : localStorage.getItem("openBlank") == 'true' ? true : false,
		}
	},
	mutations: {
		setOpenBlank(state, value) {
			state.openBlank = value
			localStorage.setItem("openBlank", value)
		},
		getSoftwareQuestion(state) {
			informationService.getSoftwareQuestion(res => {
				let win = []
				let mac = []
				for (const item of res) {
					if (item.type == 1) {
						win.push(item)
					} else {
						mac.push(item)
					}
				}
				state.winQuestion = win
				state.macQuestion = mac
			})
		},
	},
	getters: {

	},
	actions: {

	}
})

export default store

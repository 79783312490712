import all from '@/pages/resource/ResourceAll.vue'
import details from '@/pages/resource/ResourceDetails.vue'

export default [
  {
    path: '/res/all/:id?',
    name: 'resourceAll',
    component: all,
    meta: {
      title: '原子闪修 - 免费电影、电视剧、动漫、小说资源'
    }
  },
  {
    path: '/res/:id?',
    name: 'resource',
    component: details,
  },
]
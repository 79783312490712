import {
  GET,
} from '../common/api/service.js'
import progress from '@/common/nprogress'

const URL = {
  resource: "service/information/apiResource/",
  resourceCategory: 'service/information/apiResourceCategory/',
  resourceLike: "service/information/resourceLike/",
  count: "service/information/resourceCount/"
}
const api = {
  getResource(params, succ) {
    let requestOptions = {
      path: URL.resource,
      params: params,
      isLoading: true,
      succ: succ,
      complete: () => {
        progress.done()
      }
    }
    GET(requestOptions)
  },
  resourceLike(id, num = 1) {
    let path = null
    if (num > 1) {
      path = URL.resourceLike + id + "/?num=" + num
    } else {
      path = URL.resourceLike + id + "/"
    }
    let requestOptions = {
      path: path,
    }
    GET(requestOptions)
  },
  count(id) {
    let requestOptions = {
      path: URL.count + id + "/",
    }
    GET(requestOptions)
  },
  getResourceCategory(id, succ) {
    let requestOptions = {
      params: { id: id },
      path: URL.resourceCategory,
      succ: succ,
      complete: () => {
        progress.done()
      }
    }
    GET(requestOptions)
  },
  getResourceDetails(id, succ) {
    let requestOptions = {
      path: URL.resource + id + "/",
      isLoading: true,
      succ: succ,
      complete: () => {
        progress.done()
      }
    }
    GET(requestOptions)
  },
}
export default api

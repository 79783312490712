export default {
  props: {
    type: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    type(val) {
      this.setColor(val);
    }

  },

  data() {
    return {
      colors: [// 蓝色
      {
        color1: "#45E8FF",
        color2: "#2BA7FC",
        color3: "#2AA5FC",
        color4: "#43B7FB",
        color5: "#2AA5FC"
      }, // 红色
      {
        color1: "#FF8CBC",
        color2: "#FB585C",
        color3: "#2AA5FC",
        color4: "#FF7579",
        color5: "#FB6064"
      }],
      color: null
    };
  },

  methods: {
    setColor(value) {
      if (value == this.WIN) {
        this.color = this.colors[0];
      } else {
        this.color = this.colors[1];
      }
    },

    top() {
      return this.$refs.quoteLayout.getBoundingClientRect().top;
    }

  },

  created() {
    this.setColor(this.type);
  }

};
import MainHeader from "@/components/header/MainHeader.vue";
import FooterView from "@/components/FooterView.vue";
import YzChat from "@/components/chat/YzChat.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    MainHeader,
    FooterView,
    YzChat
  },
  methods: { ...mapMutations(["getSoftwareQuestion"])
  },

  mounted() {
    this.getSoftwareQuestion();
  }

};